import {
  OrderDetailsContext,
  OrderStatusReportContext,
} from '@betotrace/modules/order/contexts';
import ShareOrderContext from '@betotrace/modules/order/contexts/shareOrderContext/shareOrder.context';
import { resourceAccessPermission } from '@betotrace/shared/types/resouceAccessPermissions';
import { Permissions } from '@betotrace/shared/utils';
import { useContext } from 'react';
import { IUserOrder } from '../types/useOrderTypes';
import { OrderTypes } from './useOrder';
import { OrderSource, Webproxy_OrderStatus } from '@betotrace/shared/graphql/generated';
import { useResponsiveContext } from '@betotrace/shared/contexts';

const useCustomerOrder = () => {
  const orderContext = useContext(OrderDetailsContext);
  const sharedOrderContext = useContext(ShareOrderContext);
  const statusReportCtx = useContext(OrderStatusReportContext);
  const { isMobile } = useResponsiveContext();

  const orderPermission = Permissions({
    key: resourceAccessPermission.customer_order_manage,
  });

  const createOrderPermission = Permissions({
    key: resourceAccessPermission.customer_order_create,
  });

  const data: IUserOrder = {
    key: OrderTypes.customer,
    shouldBeAuth: true,
    general: {
      showBackButton: true,
      newOrderButton: {
        show: createOrderPermission,
        disabled: false,
      },
      showActionBar: true,
      showOrderStatus: orderPermission,
      showNavbar: true,
      showTopNavbar: false,
      showPopover: false,
    },
    orderStatus: {
      showButtons: orderContext?.orderData?.status == Webproxy_OrderStatus.PendingPrivateCustomerApproval ? false : true,
    },
    supplierSettings: {
      checkEditExternalOrder: true,
    },
    actionBar: {
      types: {
        reorder: {
          show:
            orderContext?.orderData?.supplierBranch && createOrderPermission && orderContext?.orderData.source == OrderSource.Internal,
          disabled: false,
        },
        favorite: {
          show:
            Boolean(orderContext?.orderData?.supplierBranch) && orderPermission && orderContext?.orderData.source == OrderSource.Internal,
          disabled: false,
        },
        print: {
          show: isMobile
            ? false
            : orderContext?.orderData?.source === OrderSource.Internal,
          disabled: false,
        },
        share: {
          show: true,
          disabled: false,
          isActive: sharedOrderContext?.sharedOrderList?.length > 0,
        },
      },
    },
    orderDetailsInfoRight: {
      show: true,
    },
    tabs: {
      productDetails: {
        hide: false,
        disabled: false,
      },
      deliveries: {
        hide:
          orderContext?.orderData?.quantity?.assigned === 0 &&
          orderContext?.orderData?.quantity?.arrived === 0 &&
          orderContext?.orderData?.quantity?.delivered === 0,
        disabled: false,
      },
      manualDeliveries: {
        hide: false,
        disabled: false,
      },
      orderHistory: {
        hide: false,
        disabled: false,
      },
      statusReport: {
        hide: statusReportCtx?.dataOrderConfirmations?.length < 1,
        disabled: false,
      },
      documents: {
        hide: false,
        disabled: false,
      },
    },
    userMenu: {
      show: true,
      disabled: false,
    },
  };

  return data;
};

export default useCustomerOrder;

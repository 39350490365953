import { OrderDetailsContext } from '@betotrace/modules/order/contexts';
import {
  OrderDetailsQuery,
  OrderShareAccessType,
  OrderShareQuery,
  OrderSource,
  useOrderShareQuery,
} from '@betotrace/shared/graphql/generated';
import { getPassiveMenuItems } from '@betotrace/shared/utils/helper/helper';
import { useContext } from 'react';
import { OrderTypes } from './useOrder';
import { matchPath } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import { UserType } from '@betotrace/shared/utils';
import ShareOrderPopover from '@betotrace/modules/pages/NewStructure/components/Popover/ShareOrderPopover';
import PassivePopover from '@betotrace/modules/pages/NewStructure/components/Popover/PassivePopover';
import {
  PublicContext,
  useAuthContext,
  useResponsiveContext,
} from '@betotrace/shared/contexts';
import { PublicType } from '@betotrace/shared/contexts/publicContext/public.context';

let isLoading = true;

const usePublicOrder = () => {
  const { pathname } = useLocation();
  const params = useParams<{ userType: OrderTypes; id: string }>();
  const matchParam = matchPath(
    { path: '/public/:userType/order/:id' },
    pathname
  );
  const paramUserType =
    params?.userType?.toLowerCase() ||
    matchParam?.params?.userType?.toLowerCase();

  const userType = UserType();
  const orderContext = useContext(OrderDetailsContext);
  const publicContext = useContext(PublicContext);
  const auth = useAuthContext();
  const { isMobile } = useResponsiveContext();

  const handleOrderShareData = (data: OrderShareQuery) => {
    if (
      data?.orderShare?.__typename === 'OrderShareUser' &&
      paramUserType !== data?.orderShare?.userType?.toLowerCase()
    ) {
      orderContext.setShouldRedirect(true);
    } else if (
      auth.user?.business_type &&
      auth.user?.business_type?.toLowerCase() !== paramUserType
    ) {
      orderContext.setShouldRedirect(true);
    } else if (
      data?.orderShare?.__typename === 'OrderSharePassiveSupplier' &&
      paramUserType !== OrderTypes.supplier
    ) {
      orderContext.setShouldRedirect(true);
    } else {
      isLoading = false;
    }

    const orderRequestedBy = orderContext?.orderData?.requestedBy;
    const orderShare = data?.orderShare;
    let publicType: PublicType;

    if (orderRequestedBy?.__typename === 'OrderRequestedByUnknowUser') {
      publicType = 'privateCustomer';
    } else if (orderShare?.__typename === 'OrderSharePassiveSupplier') {
      publicType = 'passiveSupplier';
    } else {
      publicType = 'shared';
    }

    publicContext.setPublicType(publicType);
  };

  const { data: orderShareData } = useOrderShareQuery({
    skip: !params?.id || !matchParam?.params?.id,
    variables: {
      request: {
        orderShareId: params?.id,
      },
    },
    onCompleted: handleOrderShareData,
  });

  if (
    orderContext?.orderData?.requestedBy?.__typename ===
    'OrderRequestedByUnknowUser'
  ) {
    return {
      key: OrderTypes.customer,
      shouldBeAuth: false,
      general: {
        showBackButton: false,
        newOrderButton: {
          show: false,
          disabled: false,
        },
        showActionBar: false,
        showOrderStatus: true,
        showNavbar: false,
        showTopNavbar: true,
        showPopover: false,
      },
      orderStatus: {
        showButtons: true,
      },
      actionBar: {
        types: {
          reorder: {
            show: false,
            disabled: true,
          },
          favorite: {
            show: false,
            disabled: true,
          },
          print: {
            show: isMobile
              ? false
              : orderContext?.orderData?.source === OrderSource.Internal,
            disabled: true,
          },
          share: {
            show: false,
            disabled: true,
            isActive: false,
          },
        },
      },
      orderDetailsInfoRight: {
        show: false,
      },
      tabs: {
        productDetails: {
          hide: false,
          disabled: false,
        },
        deliveries: {
          hide: false,
          disabled: false,
        },
        manualDeliveries: {
          hide: true,
          disabled: true,
        },
        orderHistory: {
          hide: false,
          disabled: false,
        },
        statusReport: {
          hide: true,
          disabled: true,
        },
        documents: {
          hide: true,
          disabled: true,
        },
      },
      userMenu: {
        show: false,
        disabled: true,
      },
    };
  }

  const data = getListBasedOnType(
    orderShareData,
    paramUserType,
    orderContext?.orderData,
    userType,
    isMobile
  );

  return data;
};

export default usePublicOrder;

const getListBasedOnType = (
  data: OrderShareQuery,
  paramUserType: string,
  orderData: OrderDetailsQuery['order'],
  userType: string,
  isMobile: boolean
) => {
  const menuItems = getPassiveMenuItems(
    true,
    (data?.orderShare?.__typename === 'OrderSharePassiveSupplier'
      ? OrderTypes.supplier
      : (data?.orderShare?.userType?.toLowerCase() as OrderTypes)) ??
      (paramUserType as OrderTypes)
  );
  switch (data?.orderShare?.__typename) {
    // passive supplier
    case 'OrderSharePassiveSupplier':
      return {
        key: OrderTypes.supplier,
        shouldBeAuth: false,
        isLoading: isLoading,
        general: {
          showBackButton: false,
          newOrderButton: {
            show: false,
            disabled: false,
          },
          showActionBar: true,
          showOrderStatus: true,
          showNavbar: true,
          showTopNavbar: false,
          showPopover: true,
        },
        orderStatus: {
          showButtons: true,
        },
        popover: {
          popoverData: <PassivePopover />,
        },
        actionBar: {
          types: {
            reorder: {
              show: false,
              disabled: true,
            },
            favorite: {
              show: false,
              disabled: true,
            },
            print: {
              show: isMobile ? false : true,
              disabled: true,
            },
            share: {
              show: true,
              disabled: true,
              isActive: false,
            },
          },
        },
        orderDetailsInfoRight: {
          show: true,
        },
        tabs: {
          productDetails: {
            hide: false,
            disabled: false,
          },
          deliveries: {
            hide: false,
            disabled: true,
          },
          manualDeliveries: {
            hide: true,
            disabled: true,
          },
          orderHistory: {
            hide: false,
            disabled: true,
          },
          statusReport: {
            hide: false,
            disabled: true,
          },
          documents: {
            hide: false,
            disabled: true,
          },
        },
        menuItems,
        userMenu: {
          show: false,
          disabled: true,
        },
      };
    // shared order
    case 'OrderShareUser':
      const isCustomer =
        data?.orderShare?.userType?.toLowerCase() === OrderTypes.customer;

      let requestedByName = '';
      if (orderData?.customer) {
        requestedByName = orderData?.customer?.name;
      } else {
        requestedByName = `${orderData?.requestedBy?.user?.firstName} ${orderData?.requestedBy?.user?.lastName}`;
      }

      return {
        key:
          (data?.orderShare?.userType?.toLowerCase() as OrderTypes) ??
          (paramUserType as OrderTypes),
        shouldBeAuth: false,
        isLoading: isLoading,
        sharedInfo: {
          fullName: `${data?.orderShare?.createdBy?.user?.firstName} ${data?.orderShare?.createdBy?.user?.lastName}`,
          sharedCompany:
            data?.orderShare?.userType?.toLowerCase() === OrderTypes.customer
              ? requestedByName
              : orderData?.supplierBranch?.name,
        },
        general: {
          showBackButton: false,
          newOrderButton: {
            show: false,
            disabled: false,
          },
          showActionBar: true,
          showOrderStatus: true,
          showNavbar: true,
          showTopNavbar: false,
          showPopover: !userType,
        },
        orderStatus: {
          showButtons:
            data?.orderShare?.accessType === OrderShareAccessType?.Edit,
        },
        supplierSettings: {
          checkEditExternalOrder: true,
        },
        popover: {
          popoverData: <ShareOrderPopover />,
        },
        actionBar: {
          types: {
            reorder: {
              show: isMobile ? false : isCustomer,
              disabled: true,
            },
            favorite: {
              show: isMobile ? false : isCustomer,
              disabled: true,
            },
            print: {
              show: isMobile ? false : true,
              disabled: true,
            },
            share: {
              show: false,
              disabled: true,
              isActive: false,
            },
          },
        },
        orderDetailsInfoRight: {
          show: true,
        },
        tabs: {
          productDetails: {
            hide: false,
            disabled: false,
          },
          deliveries: {
            hide: false,
            disabled: false,
          },
          manualDeliveries: {
            hide: false,
            disabled: false,
          },
          orderHistory: {
            hide: false,
            disabled: false,
          },
          statusReport: {
            hide: false,
            disabled: false,
          },
          documents: {
            hide: false,
            disabled: false,
          },
        },
        ...(!userType && { menuItems }),
        userMenu: {
          show: Boolean(userType),
          disabled: !userType,
        },
      };
    // default
    default: {
      return {
        key: paramUserType as OrderTypes,
        shouldBeAuth: false,
        isLoading: isLoading,
        general: {
          showBackButton: false,
          newOrderButton: {
            show: false,
            disabled: false,
          },
          showActionBar: true,
          showOrderStatus: true,
          showNavbar: true,
          showTopNavbar: false,
          showPopover: !userType,
        },
        orderStatus: {
          showButtons: true,
        },
        popover: {
          popoverData: <PassivePopover />,
        },
        actionBar: {
          types: {
            reorder: {
              show: false,
              disabled: true,
            },
            favorite: {
              show: false,
              disabled: true,
            },
            print: {
              show: isMobile ? false : true,
              disabled: true,
            },
            share: {
              show: true,
              disabled: true,
              isActive: false,
            },
          },
        },
        orderDetailsInfoRight: {
          show: true,
        },
        tabs: {
          productDetails: {
            hide: false,
            disabled: false,
          },
          deliveries: {
            hide: false,
            disabled: true,
          },
          manualDeliveries: {
            hide: false,
            disabled: true,
          },
          orderHistory: {
            hide: false,
            disabled: true,
          },
          statusReport: {
            hide: false,
            disabled: true,
          },
          documents: {
            hide: false,
            disabled: true,
          },
        },
        ...(!userType && { menuItems }),
        userMenu: {
          show: Boolean(userType),
          disabled: !userType,
        },
      };
    }
  }
};

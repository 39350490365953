import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import {
  APIProvider,
  ProjectsContextProvider,
  ResponsiveProvider,
  StepperContextProvider,
  useAppClient,
  useAuthContext,
} from '@betotrace/shared/contexts';
import Routes from './routes/Routes';
import useGoogleMapScript
  from './shared/hooks/useGoogleMapScript/useGoogleMapScript';
import { createTheme, ThemeProvider } from '@mui/material';
import ErrorBoundary from './errorBoundary/ErrorBoundary';
import { useEffect, useState } from 'react';
import OldBrowserNotification
  from '@betotrace/shared/components/oldBrowserNotification/OldBrowserNotification';
import SignalRService from '@betotrace/services/signalRService/SignalRService';
import { useKeycloak } from '@react-keycloak/web';
import {
  Delivery,
  OrderQuantity,
  OrderStatus,
  OrderTransport,
} from '@betotrace/shared/graphql/generated';
import { makeVar } from '@apollo/client';

const defaultTheme = createTheme();

const theme = createTheme({
  components: {
    MuiCardContent: {
      styleOverrides: {
        root: {
          width: '100% !important',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: '8px !important',
          paddingBottom: '8px !important',
        },
        gutters: {
          paddingLeft: '16px !important',
          paddingRight: '16px !important',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          display: 'flex !important',
          paddingTop: '8px !important',
          paddingBottom: '8px !important',
        },
        gutters: {
          paddingLeft: '16px !important',
          paddingRight: '16px !important',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '16px !important',
          margin: '4px !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px !important',
          padding: '6px 16px !important',
          '&.Mui-disabled': {
            backgroundColor: '#d4d4d4 !important',
            border: '1px solid transparent !important',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #e5e5e5',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#2196F3',
    },
    error: {
      ...defaultTheme.palette.error,
      dark: '#C62828',
      light: '#FCE7E7',
    },
  },
});

interface UpdatedOrder {
  id: string;
  quantity: {
    arrived: number;
    assigned: number;
    delivered: number;
    manualDelivered: number;
    ordered: number;
    pumped: number;
    returned: number;
  };
  status: OrderStatus;
  transport: {
    date: string;
  };
}
export interface UpdatedDeliveryHistory {
  deliveryId: string;
  newStatus: string;
  oldStatus: string;
  createdAt: string;
}

export const updatedOrderVar = makeVar(null);
export const createdDeliveryVar = makeVar(null);
export const updatedDeliveryVar = makeVar(null);
export const updatedDeliveryHistoryVar = makeVar(null);
const App = () => {
  const userData = useAuthContext();
  const client = useAppClient();
  const { keycloak } = useKeycloak();
  useGoogleMapScript();
  const [isInternetExplorer, setIsInternetExplorer] = useState(false);

  useEffect(() => {
    const token = keycloak.token;
    if (!token) {
      return;
    }
    const signalRService = new SignalRService(token);

    signalRService.addEventListener('OrderUpdated', (data: UpdatedOrder) => {
      const { id } = data;
      client.cache.modify({
        id: client.cache.identify({ __typename: 'Order', id }),
        fields: {
          status: () => data.status,
          quantity: (quantity: OrderQuantity) => ({
            ...quantity,
            ordered: data.quantity.ordered,
          }),
          transport: (existingTransport: OrderTransport) => ({
            ...existingTransport,
            date: data.transport.date,
          }),
        },
      });
      updatedOrderVar(data);
    });

    signalRService.addEventListener('DeliveryCreated', (data: Delivery) => {
      createdDeliveryVar(data);
    });

    signalRService.addEventListener('DeliveryUpdated', (data: Delivery) => {
      updatedDeliveryVar(data);
    });

    signalRService.addEventListener(
      'DeliveryHistoryCreated',
      (data: UpdatedDeliveryHistory) => {
        updatedDeliveryHistoryVar(data);
      }
    );
    return () => {
      signalRService.removeEventListener('OrderUpdated');
      signalRService.removeEventListener('DeliveryCreated');
      signalRService.removeEventListener('DeliveryUpdated');
      signalRService.removeEventListener('DeliveryHistoryCreated');
    };
  }, [keycloak.token]);

  useEffect(() => {
    const ua = window.navigator.userAgent;
    const isInternetExplorerBrowser =
      ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
    setIsInternetExplorer(isInternetExplorerBrowser);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider
        locale={userData.locale}
        messages={userData.messages}
        defaultLocale={userData.locale}
      >
        {isInternetExplorer ? (
          <OldBrowserNotification />
        ) : (
          <ErrorBoundary>
            <ResponsiveProvider>
              <APIProvider>
                <StepperContextProvider>
                  <ProjectsContextProvider>
                    <BrowserRouter>
                      <Routes />
                    </BrowserRouter>
                  </ProjectsContextProvider>
                </StepperContextProvider>
              </APIProvider>
            </ResponsiveProvider>
          </ErrorBoundary>
        )}
      </IntlProvider>
    </ThemeProvider>
  );
};

export { App };

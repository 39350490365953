import { FormattedMessage } from 'react-intl';

interface Statuses {
  [key: string]: {
    [key: string]: {
      title: JSX.Element;
      className: string;
      subtitle?: JSX.Element;
    };
  };
}

const pendingBadge = {
  title: <FormattedMessage id="customBadge.pending" defaultMessage="Pending" />,
  className: 'status-pending',
};

const actionBadge = {
  title: <FormattedMessage id="customBadge.action" defaultMessage="Action" />,
  className: 'status-action',
};

const plannedBadge = {
  title: <FormattedMessage id="badge.Planned" defaultMessage="Planned" />,
  className: 'status-planned',
};

const inProgressBadge = {
  title: (
    <FormattedMessage
      id="customBadge.inProgress"
      defaultMessage="In progress"
    />
  ),
  className: 'status-inProgress',
};

const completedBadge = {
  title: <FormattedMessage id="completed" defaultMessage="Completed" />,
  className: 'status-completed',
};

const cancelledBadge = {
  title: <FormattedMessage id="badge.Cancelled" defaultMessage="Cancelled" />,
  className: 'status-cancelled',
};

const onRouteBadge = {
  title: (
    <FormattedMessage id="customBadge.onRoute" defaultMessage="On route" />
  ),
  className: 'status-onRoute',
};

const arrivedBadge = {
  title: <FormattedMessage id="badge.Arrived" defaultMessage="Arrived" />,
  className: 'status-arrived',
};

const unloadingBadge = {
  title: <FormattedMessage id="badge.Unloading" defaultMessage="Unloading" />,
  className: 'status-unloading',
};

const unloadedBadge = {
  title: <FormattedMessage id="badge.Unloaded" defaultMessage="Unloaded" />,
  className: 'status-unloaded',
};

export const statuses: Statuses = {
  planned: {
    supplier: {
      ...plannedBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.planned.desc"
          defaultMessage="This order is planned"
        />
      ),
    },
    customer: {
      ...plannedBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.planned.desc"
          defaultMessage="This order is planned"
        />
      ),
    },
  },
  completed: {
    supplier: {
      ...completedBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.completed.desc"
          defaultMessage="This order is completed"
        />
      ),
    },
    customer: {
      ...completedBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.completed.desc"
          defaultMessage="This order is completed"
        />
      ),
    },
  },
  cancelled: {
    supplier: {
      ...cancelledBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.cancelled.desc"
          defaultMessage="This order is cancelled"
        />
      ),
    },
    customer: {
      ...cancelledBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.cancelled.desc"
          defaultMessage="This order is cancelled"
        />
      ),
    },
  },
  inprogress: {
    supplier: {
      ...inProgressBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.inProgress.desc"
          defaultMessage="Deliveries in progress"
        />
      ),
    },
    customer: {
      ...inProgressBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.inProgress.desc"
          defaultMessage="Deliveries in progress"
        />
      ),
    },
  },
  pendingcustomereditapproval: {
    supplier: {
      ...pendingBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.pendingCustomerEditApproval.supplier.desc"
          defaultMessage="The edits you made to this order have not yet been accepted by the customer."
        />
      ),
    },
    customer: {
      ...actionBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.pendingCustomerEditApproval.customer.desc"
          defaultMessage="The supplier has made edits to this order. Please accept."
        />
      ),
    },
  },
  pendingcustomerapproval: {
    supplier: {
      ...pendingBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.pendingCustomerEditApproval.supplier.desc"
          defaultMessage="The edits you made to this order have not yet been accepted by the customer."
        />
      ),
    },
    customer: {
      ...actionBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.pendingCustomerEditApproval.customer.desc"
          defaultMessage="The supplier has made edits to this order. Please accept."
        />
      ),
    },
  },
  pendingprivatecustomerapproval: {
    supplier: {
      ...pendingBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.pendingPrivateCustomerApproval.supplier.desc"
          defaultMessage="Waiting Customer T&Cs acceptance"
        />
      ),
    },
    customer: {
      ...actionBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.pendingPrivateCustomerApproval.customer.desc"
          defaultMessage="Supplier created this order. Please accept the T&Cs"
        />
      ),
    },
  },
  pendingsuppliereditapproval: {
    supplier: {
      ...actionBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.pendingSupplierEditApproval.supplier.desc"
          defaultMessage="The customer has made edits to this order. Please review, edit or accept."
        />
      ),
    },
    customer: {
      ...pendingBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.pendingSupplierEditApproval.customer.desc"
          defaultMessage="The edits you made to this order have not yet been accepted by the supplier."
        />
      ),
    },
  },
  pendingsupplierapproval: {
    supplier: {
      ...actionBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.pendingSupplierApproval.supplier.desc"
          defaultMessage="The customer has sent you an order request. Please review, edit or accept."
        />
      ),
    },
    customer: {
      ...pendingBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.pendingSupplierEditApproval.customer.desc"
          defaultMessage="The edits you made to this order have not yet been accepted by the supplier."
        />
      ),
    },
  },
  inreviewbysupplier: {
    supplier: {
      ...actionBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.inReviewBySupplier.supplier.desc"
          defaultMessage="Order in review, please edit or accept."
        />
      ),
    },
    customer: {
      ...pendingBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.inReviewBySupplier.customer.desc"
          defaultMessage="This order is under review by the supplier."
        />
      ),
    },
  },
  editinreviewbysupplier: {
    supplier: {
      ...actionBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.editInReviewBySupplier.supplier.desc"
          defaultMessage="The edits made by the customer are under review. Please confirm or edit."
        />
      ),
    },
    customer: {
      ...pendingBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.editInReviewBySupplier.customer.desc"
          defaultMessage="The edits you made to this order are in review by the supplier."
        />
      ),
    },
  },
  pendingcustomercancelationreceipt: {
    supplier: {
      ...pendingBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.pendingCustomerCancelationReceipt.supplier.desc"
          defaultMessage="This order has been cancelled, cancellation has not yet been confirmed by the customer."
        />
      ),
    },
    customer: {
      ...actionBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.pendingCustomerCancelationReceipt.customer.desc"
          defaultMessage="This order has been cancelled by the supplier, please confirm."
        />
      ),
    },
  },
  pendingsuppliercancelationreceipt: {
    supplier: {
      ...actionBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.pendingSupplierCancelationReceipt.supplier.desc"
          defaultMessage="This order has been cancelled by the customer, please confirm."
        />
      ),
    },
    customer: {
      ...pendingBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.pendingSupplierCancelationReceipt.customer.desc"
          defaultMessage="This order has been cancelled, cancellation has not yet been confirmed by the supplier."
        />
      ),
    },
  },
  sentnotconfirmed: {
    customer: {
      ...actionBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.sentNotConfirmed.customer.desc"
          defaultMessage="This order was sent to the supplier and can be confirmed by you."
        />
      ),
    },
  },
  quickeditnotconfirmed: {
    customer: {
      ...actionBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.quickEditNotConfirmed.customer.desc"
          defaultMessage="The change request was sent to the supplier and can be confirmed by you"
        />
      ),
    },
  },
  cancelationnotificationsent: {
    customer: {
      ...actionBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.cancelationNotificationSent.customer.desc"
          defaultMessage="The cancellation request was sent to the supplier and can be confirmed by you."
        />
      ),
    },
  },
  cancelationconfirmed: {
    customer: {
      ...cancelledBadge,
      subtitle: (
        <FormattedMessage
          id="customBadge.cancelled.desc"
          defaultMessage="This order is cancelled"
        />
      ),
    },
  },
  onroute: {
    customer: {
      ...onRouteBadge,
    },
    supplier: {
      ...onRouteBadge,
    },
  },
  arrived: {
    customer: {
      ...arrivedBadge,
    },
    supplier: {
      ...arrivedBadge,
    },
  },
  unloading: {
    customer: {
      ...unloadingBadge,
    },
    supplier: {
      ...unloadingBadge,
    },
  },
  unloaded: {
    customer: {
      ...unloadedBadge,
    },
    supplier: {
      ...unloadedBadge,
    },
  },
};
